import { Toast } from "../../components";

const createNotificationSlice = (set, get) => ({
  notification: {
    message: "",
    type: "", // e.g., "error", "success"
    isVisible: false,
  },
  setNotification: (message, type) => {
    set({ notification: { message, type } });
    // Show toast notification
    Toast({ message, type });
  },
  clearNotification: () => set({ notification: null }),
});

export default createNotificationSlice;
