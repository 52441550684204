import React from "react";
import { Routes, Route } from "react-router-dom";
import { BottomNav } from "../components";
import {
  Home,
  Login,
  NotFound,
  Profile,
  Transaction,
  AddProduct,
  Product,
} from "../pages";

export const Mobile = () => {
  return (
    <div className="mobile">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/product" element={<Product />} />
        <Route path="/transaction" element={<Transaction />} />
        <Route path="/add-product" element={<AddProduct />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <nav id="bottom-navigation">
        <BottomNav />
      </nav>
    </div>
  );
};
