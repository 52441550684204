import { createBrowserHistory } from "history";
import { Mobile } from "./device";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const history = createBrowserHistory({ window });

function App() {
  return (
    <HistoryRouter history={history}>
      <div className="App">
        <Mobile />
        <ToastContainer position="top-right" autoClose={3000} />
      </div>
    </HistoryRouter>
  );
}

export default App;
