import { useEffect } from "react";
import useStore from "../store";
import { useNavigate } from "react-router-dom";

const useAuthRedirect = () => {
  const isAuthenticated = useStore((state) => state.isAuthenticated);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);
};

export default useAuthRedirect;
