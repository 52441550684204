import { useEffect, useState } from "react";
import useStore from "../store";

const useFetchPaymentCount = (userId) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const paymentCount = useStore((state) => state.paymentCount);
  const fetchPaymentCount = useStore((state) => state.fetchPaymentCount);

  useEffect(() => {
    const fetch = async () => {
      try {
        setIsLoading(true);
        setError(null);
        await fetchPaymentCount("pending");
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetch();
  }, [fetchPaymentCount]);

  return { paymentCount, isLoading, error };
};

export default useFetchPaymentCount;
