import React from "react";
import { CardProduct, TopNav } from "../components";
import { useNavigate } from "react-router-dom";
import { useFetchProducts } from "../hooks";
import { Plus } from "iconoir-react";

function Product() {
  const navigate = useNavigate();
  const { products, isLoading, error } = useFetchProducts();

  const handleOnClickProduct = (e) => {
    navigate(`/product/${e.slug}`, { state: e });
  };

  const handleAddProduct = () => {
    navigate(`/add-product`);
  };

  const handleEditProduct = (product) => {
    console.log(`Edit clicked for product ${product.productName}`);
    // Handle edit action here
  };

  const handleDeleteProduct = (product) => {
    console.log(`Delete clicked for product ${product.productName}`);
    // Handle delete action here
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!products) {
    return <div>No products information available</div>;
  }

  return (
    <>
      <TopNav
        pageName={"Product"}
        isRightCustom={true}
        disableBack={true}
        rightComponent={() => (
          <button
            onClick={handleAddProduct}
            className="transition-opacity duration-200 ease-in-out opacity-100 hover:opacity-75 active:opacity-50 focus:outline-none focus:ring-2 focus:ring-transparent"
          >
            <div className="flex flex-row items-center bg-[#0C64C610] px-2 py-1 rounded-md">
              <Plus strokeWidth={2} width={20} height={20} color="#0C64C690" />
              <div className="text-[10px] font-bold text-[#0C64C690]">
                Add Product
              </div>
            </div>
          </button>
        )}
      />
      <div className="px-[20px] pb-[150px]">
        <div className="flex flex-col space-y-5">
          {products.data.map((e, i) => (
            <CardProduct
              key={i.toString()}
              handleNavigate={() => handleOnClickProduct(e)}
              title={e.productName}
              desc={e.productDesc}
              price={e.price}
              imgUrl={e.images.url}
              handleEdit={() => handleEditProduct(e)}
              handleDelete={() => handleDeleteProduct(e)}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default Product;
