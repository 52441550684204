import React from "react";

function InputField({
  id,
  name,
  value,
  handleChange,
  label,
  type = "text",
  placeholder,
}) {
  return (
    <div className="mb-4">
      <label htmlFor={id} className="font-bold mb-2 text-black500">
        {label}
      </label>
      <input
        type={type}
        id={id}
        name={name}
        value={value}
        onChange={handleChange}
        className="w-full form-input px-4 py-3 rounded border mt-2 text-black font-bold"
        placeholder={placeholder}
      />
    </div>
  );
}

export default InputField;
