import React from "react";
import { formatAmount, truncateString } from "../utils";
import { MoreHoriz } from "iconoir-react";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";

function CardProduct({
  imgUrl,
  title,
  desc,
  price,
  handleNavigate,
  handleEdit,
  handleDelete,
}) {
  return (
    <div
      onClick={handleNavigate}
      className="p-[10px] rounded-[14px] bg-[#144ddd10] relative"
    >
      <div className="flex flex-row space-x-2 items-center">
        <div className="w-[80px] h-[80px] flex-shrink-0">
          <img src={imgUrl} className="w-full h-full object-cover rounded-lg" />
        </div>
        <div className="flex flex-col flex-grow">
          <div className="flex flex-row justify-between items-center w-full">
            <div className="font-bold text-[16px] text-black">{title}</div>
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <MenuButton
                  onClick={(e) => e.stopPropagation()}
                  className="transition-opacity duration-200 ease-in-out opacity-100 hover:opacity-75 active:opacity-50 focus:outline-none focus:ring-2 focus:ring-transparent"
                >
                  <MoreHoriz
                    strokeWidth={2}
                    width={20}
                    height={20}
                    color="#0C64C6"
                  />
                </MenuButton>
              </div>

              <MenuItems className="absolute right-0 z-10 mt-0 w-20 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <MenuItem>
                    {({ active }) => (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEdit();
                        }}
                        className={`${
                          active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                        } group flex w-full items-center px-4 py-2 text-sm`}
                      >
                        Edit
                      </button>
                    )}
                  </MenuItem>
                  <MenuItem>
                    {({ active }) => (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDelete();
                        }}
                        className={`${
                          active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                        } group flex w-full items-center px-4 py-2 text-sm`}
                      >
                        Delete
                      </button>
                    )}
                  </MenuItem>
                </div>
              </MenuItems>
            </Menu>
          </div>
          <div className="font-light text-black500">
            {truncateString(desc, 20)}
          </div>
          <div className="font-bold text-[20px] text-black">
            Rp{formatAmount(price)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardProduct;
