import { CardWallet } from "iconoir-react";
import React from "react";
// import { IconArrowTrending } from "../assets";

function CardResume({
  percentage = "20%",
  title = "title",
  periode = "periode",
  value,
}) {
  return (
    <div className="flex flex-col bg-blue-50   p-4 rounded-[4px] space-y-2 w-full">
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center space-x-2">
          {/* <IconArrowTrending size={18} /> */}
          <div className="text-[18px]">{percentage}</div>
        </div>
        <div className="px-2 py-1 bg-blue-300 rounded-md">
          <CardWallet height={30} className=" text-blue-900" />
        </div>
      </div>
      <div className="flex flex-col items-end pt-2">
        <div className="text-[16px] text-[#024cc2ee] font-semibold">
          {title}
        </div>
        <div className="text-[14px] text-[#0b4094] font-thin">{periode}</div>
        <div className="flex text-[18px] text-blue-900 font-bold items-end pt-2">
          {value}
        </div>
      </div>
    </div>
  );
}

export default CardResume;
