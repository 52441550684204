import React from "react";
import { CardResume, TopNav } from "../components";
import {
  useFetchTicketCount,
  useFetchUserCount,
  useFetchPaymentCount,
} from "../hooks";

const Home = () => {
  const {
    ticketCount,
    isLoading: isLoadingTickets,
    error: errorTickets,
  } = useFetchTicketCount();
  const {
    userCount,
    isLoading: isLoadingUsers,
    error: errorUsers,
  } = useFetchUserCount();
  const {
    paymentCount,
    isLoading: isLoadingPayments,
    error: errorPayments,
  } = useFetchPaymentCount();

  const isLoading = isLoadingTickets || isLoadingUsers || isLoadingPayments;
  const error = errorTickets || errorUsers || errorPayments;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!ticketCount) {
    return <div>No ticket information available</div>;
  }

  return (
    <>
      <div className="pt-4">
        <TopNav
          disableBack={true}
          isRightCustom={false}
          isLeftComponent={true}
          leftComponent={() => (
            <div className="px-0">
              <img
                src="./logo192.png"
                className="h-[60px] w-[60px] object-fill"
                alt="logo192"
              />
            </div>
          )}
        />
      </div>
      <div className="pb-[100px]">
        <div className="flex flex-col space-y-4">
          <div className="flex flex-row space-x-4 px-4 mt-4 w-[100vw]">
            <CardResume title="Total Sales" percentage="" periode="" value="" />
            <CardResume
              title="Total Users"
              percentage=""
              periode=""
              value={userCount?.count}
            />
          </div>
          <div className="flex flex-row space-x-4 px-4 mt-4 w-[100vw]">
            <CardResume
              title="Ticket Sold"
              percentage=""
              periode=""
              value={ticketCount?.count}
            />
            <CardResume
              title="Pending Orders"
              percentage=""
              periode=""
              value={paymentCount?.count}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
