import React from "react";

const IconText = {
  V: ({
    icon,
    label,
    fontStyle = "",
    fontFamily = "plusjakarta",
    spacer = 3,
  }) => {
    return (
      <div className="flex items-center flex-col">
        <div className="">{React.createElement(icon)}</div>
        <div className={`pl-[${spacer}px]`}>
          <div className={`font-${fontFamily}` + ` ` + `${fontStyle}`}>
            {label}
          </div>
        </div>
      </div>
    );
  },
  H: ({
    icon,
    label,
    fontStyle = "",
    fontFamily = "plusjakarta",
    spacer = 3,
  }) => {
    return (
      <div className="flex flex-row items-center">
        <div className="">{React.createElement(icon)}</div>
        <div className={`pt-[${spacer}px]`}>
          <div className={`font-${fontFamily}` + ` ` + `${fontStyle}`}>
            {label}
          </div>
        </div>
      </div>
    );
  },
};

export default IconText;
