import { useEffect, useState } from "react";
import useStore from "../store";

const useFetchProducts = (params) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const products = useStore((state) => state.products);
  const fetchProducts = useStore((state) => state.fetchProducts);
  useEffect(() => {
    const fetch = async () => {
      try {
        setIsLoading(true);
        setError(null);
        await fetchProducts(params);
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetch();
  }, [fetchProducts]);

  return { products, isLoading, error };
};

export default useFetchProducts;
