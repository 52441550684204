import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <h1>404 - Page Not Found</h1>
      <p>The page you are looking for does not exist.</p>
      <Link to={"/"}>
        <div className=" underline italic text-blue-500 text-[16px] p-4">
          back to home
        </div>
      </Link>
    </div>
  );
};

export default NotFound;
