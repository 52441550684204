import apiServices from "../../services/apiService";

const createUserSlice = (set, get) => ({
  users: [],
  user: null,
  token: null,
  userCount: null,
  userId: null,
  data: null,
  isLoading: false,
  error: null,
  setUser: (user, token, data) => set({ user, token, data }),
  setLoading: (isLoading) => set({ isLoading }),
  setError: (error) => set({ error }),
  clearUser: () => {
    set({ user: null, token: null, data: null });
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("username");
    localStorage.removeItem("email");
    localStorage.removeItem("age");
    localStorage.removeItem("fullname");
  },
  register: async (userData) => {
    try {
      const data = await apiServices.register(userData);
      set({ data: data });
      return data;
    } catch (error) {
      console.error("Registration failed:", error);
    }
  },
  login: async (credentials) => {
    try {
      const data = await apiServices.login(credentials);
      set({ data });
      localStorage.setItem("token", data.accessToken);
      localStorage.setItem("userId", data.user.id);
      localStorage.setItem("username", data.user.username);
      localStorage.setItem("email", data.user.email);
      localStorage.setItem("age", data.user.age);
      localStorage.setItem("fullname", data.user.fullname);
      set({ isAuthenticated: true });
      return data;
    } catch (error) {
      console.error("Login failed:", error);
      return null;
    }
  },
  profile: async () => {
    set({ isLoading: true, error: null });
    try {
      const response = await apiServices.profile();
      set({ user: response.data });
      return response;
    } catch (error) {
      set({ error: "Failed to load profile." });
      console.error("Profile load failed:", error);
      return null;
    } finally {
      set({ isLoading: false });
    }
  },
  logout: () => {
    get().clearUser();
    set({ isAuthenticated: false });
    window.location.href = "/login"; // Redirect to home page
  },
  verify: async (tokenVerify) => {
    try {
      const { token, user } = await apiServices.verify(tokenVerify);
      set({ token, userId: user.id });
      localStorage.setItem("token", token);
      localStorage.setItem("userId", user.id);
      localStorage.setItem("username", user.username);
      localStorage.setItem("email", user.email);
      localStorage.setItem("age", user.age);
      localStorage.setItem("fullname", user.fullname);
      set({ isAuthenticated: true });
    } catch (error) {
      console.error("Verify failed:", error);
    }
  },
  checkTokenValidity: async () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        await apiServices.checkToken(token);
        set({ isAuthenticated: true });
      } catch (error) {
        console.error("Token validation failed:", error);
        set({ isAuthenticated: false });
        get().clearUser();
        window.location.href = "/login"; // Adjust this based on your routing setup
      }
    } else {
      set({ isAuthenticated: false });
    }
  },
  fetchUserCount: async () => {
    try {
      const response = await apiServices.userCount();
      set({ userCount: response });
      return response;
    } catch (error) {
      console.error("tickets load failed:", error);
      return null;
    }
  },
});

export default createUserSlice;
