import { useEffect, useState } from "react";
import useStore from "../store";

const useFetchTicketCount = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const ticketCount = useStore((state) => state.ticketCount);
  const fetchTicketCount = useStore((state) => state.fetchTicketCount);

  useEffect(() => {
    const fetch = async () => {
      try {
        setIsLoading(true);
        setError(null);
        await fetchTicketCount();
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetch();
  }, [fetchTicketCount]);

  return { ticketCount, isLoading, error };
};

export default useFetchTicketCount;
