import React from "react";
import { TopNav } from "../components";

function Transaction() {
  return (
    <>
      <TopNav pageName={"Transaction"} rightIcon={false} disableBack={true} />
      <div className="px-[20px]">
        <div className="flex flex-col space-y-5">
          <div>undercontructions...</div>
        </div>
      </div>
    </>
  );
}

export default Transaction;
