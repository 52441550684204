import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import InputField from "../components/InputField";
import { TopNav } from "../components";
import useStore from "../store";
import useAuthRedirect from "../helper/useAuthRedirect";

function Login() {
  useAuthRedirect();
  const navigate = useNavigate();
  const login = useStore((state) => state.login);
  const setNotification = useStore((state) => state.setNotification);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [isProcessing, setIsProcessing] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    try {
      const loginResponse = await login({
        email: formData.email,
        password: formData.password,
      });
      if (loginResponse) {
        navigate("/");
      } else {
        setNotification("failed error", "error");
      }
    } catch (error) {
      console.error("Login failed:", error);

      setIsProcessing(false);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="flex flex-1 flex-col h-screen">
      <TopNav pageName={"login"} disableBack={true} component={() => <></>} />
      <div className="px-4 flex flex-1 flex-col h-screen space-y-6">
        <div className="flex items-center justify-center px-0 mb-10">
          <img
            src="./logo192.png"
            className="h-[80px] w-[80px] object-fill mt-[20px]"
            alt="logo192"
          />
        </div>
        <div className="flex flex-col mb-4 space-y-[5px]">
          <InputField
            label={"Email"}
            name="email"
            value={formData.email}
            handleChange={handleChange}
            type="email"
            placeholder="emailkamu@email.com"
          />
          <InputField
            label={"Password"}
            name="password"
            value={formData.password}
            handleChange={handleChange}
            type="password"
            placeholder="passwordkamu"
          />
        </div>
        <div className="w-full my-0 bg-bluehero rounded-lg">
          <button
            onClick={handleSubmit}
            className={`py-[15px] w-[100%] font-semibold capitalize text-[#ffffff] transition-opacity duration-300 ${
              !formData.email || !formData.password || isProcessing
                ? "opacity-50 cursor-not-allowed bg-gray-400 rounded-lg"
                : "opacity-100 hover:opacity-80 bg-bluehero rounded-lg"
            }`}
          >
            Login
          </button>
        </div>
      </div>
      {isProcessing && (
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="text-white">Processing...</div>
        </div>
      )}
    </div>
  );
}

export default Login;
