import { toast } from "react-toastify";

const Toast = ({ message, type }) => {
  switch (type) {
    case "success":
      toast.success(message);
      break;
    case "error":
      toast.error(message);
      break;
    default:
      toast(message);
  }

  return null;
};

export default Toast;
