import apiService from "../services/apiService";

const handleFormAddProduct = async (formData, setLoading, setError) => {
  try {
    setLoading(true);
    const response = await apiService.addProduct(formData);
    // Tangani respons sukses
    setLoading(false);
    return response;
  } catch (error) {
    console.error("Error", error);
    // Tangani error
    setLoading(false);
    setError(error.message || "An occurred error");
  }
};

export default handleFormAddProduct;
