import apiServices from "../../services/apiService";

const createTicketSlice = (set, get) => ({
  tickets: [],
  ticketCount: 0,
  fetchTicketCount: async () => {
    try {
      const response = await apiServices.ticketCount();
      set({ ticketCount: response });
      return response;
    } catch (error) {
      console.error("tickets load failed:", error);
      return null;
    }
  },
});

export default createTicketSlice;
