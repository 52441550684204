import axios from "axios";
import useStore from "../store";

const baseUrl = `https://api-vrjourney.kasisolusi.co.id/api/v1`;
const api = axios.create({
  baseURL: `${baseUrl}`,
  headers: {
    "Content-type": "application/json",
  },
});

const getToken = () => {
  return localStorage.getItem("token");
};

api.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Fungsi untuk logout dan redirect
const logoutAndRedirect = () => {
  const { logout } = useStore.getState();
  logout();
};

// Interceptor untuk menangani respons
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      logoutAndRedirect();
    }
    return Promise.reject(error);
  }
);

export default api;
