import React from "react";
import { useNavigate } from "react-router-dom";
import { ArrowLeft, ProfileCircle } from "iconoir-react";

function TopNav({
  backTo = -1,
  pageName,
  disableBack = false,
  leftComponent,
  rightComponent,
  isLeftComponent = false,
  isRightCustom = false,
}) {
  const navigate = useNavigate();

  const _style = disableBack
    ? "flex flex-row items-center justify-between space-x-4 h-[60px]"
    : "flex flex-row items-center space-x-4 h-[60px]";

  const handleProfile = () => {
    navigate("/profile");
  };

  return (
    <div className="px-4">
      <div className={_style}>
        {disableBack ? (
          isLeftComponent ? (
            React.createElement(leftComponent)
          ) : (
            <div className="text-[20px] font-bold capitalize text-bluehero">
              {pageName}
            </div>
          )
        ) : (
          <>
            <button
              onClick={() => navigate(backTo)}
              className="focus:outline-none"
            >
              <ArrowLeft strokeWidth={3} className="text-bluehero" />
            </button>
            <div className="text-[20px] font-bold capitalize text-bluehero">
              {pageName}
            </div>
          </>
        )}
        {isRightCustom && rightComponent
          ? React.createElement(rightComponent)
          : !isRightCustom && (
              <button
                onClick={handleProfile}
                className="transition-opacity duration-200 ease-in-out opacity-100 hover:opacity-75 active:opacity-50 focus:outline-none focus:ring-2 focus:ring-transparent"
              >
                <ProfileCircle
                  strokeWidth={2}
                  width={30}
                  height={30}
                  color="#0C64C680"
                />
              </button>
            )}
      </div>
    </div>
  );
}

export default TopNav;
