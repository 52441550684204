import { create } from "zustand";
import { combine, devtools } from "zustand/middleware";
import createAuthSlice from "./slice/authSlice";
import createTicketSlice from "./slice/ticketSlice";
import createUserSlice from "./slice/userSlice";
import createPaymentSlice from "./slice/paymentSlice";
import createProductSlice from "./slice/productSlice";
import createNotificationSlice from "./slice/notificationSlice";

const useStore = create(
  devtools(
    combine(
      // Initial state, if needed
      {},
      (set, get) => ({
        ...createAuthSlice(set, get),
        ...createTicketSlice(set, get),
        ...createUserSlice(set, get),
        ...createPaymentSlice(set, get),
        ...createProductSlice(set, get),
        ...createNotificationSlice(set, get),
      })
    ),
    { name: "AdminStore" } // Optional: You can provide a name for the store
  )
);

export default useStore;
