import apiServices from "../../services/apiService";

const createProductSlice = (set, get) => ({
  products: [],
  fetchProducts: async (params) => {
    try {
      const products = await apiServices.getProducts(params);
      set({ products });
    } catch (error) {
      console.error("Fetching products failed:", error);
    }
  },
  clearProducts: () => set({ products: [] }),
});

export default createProductSlice;
