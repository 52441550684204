import api from "./httpService";

const apiServices = {
  register: async (userData) => {
    const response = await api.post("/user/register-spv", userData);
    return response.data;
  },

  login: async (credentials) => {
    const response = await api.post("/user/login-spv", credentials);
    return response.data;
  },

  addProduct: async (formData) => {
    const response = await api.post("/product", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  },

  getProducts: async (params) => {
    const response = await api.get(`/product`, { params });
    return response.data;
  },

  verify: async (token) => {
    const response = await api.get(`/verify?token=${token}`);
    return response.data;
  },

  checkToken: async () => {
    const response = await api.get(`/verify/access-token`);
    return response.data;
  },

  profile: async () => {
    const response = await api.get(`/user/profile`);
    return response.data;
  },

  ticketCount: async () => {
    const response = await api.get(`/ticket/list/count`);
    return response.data;
  },

  paymentCount: async (status) => {
    const params = status ? { status } : {};
    const response = await api.get(`/payment/list/count`, { params });
    return response.data;
  },

  userCount: async () => {
    const response = await api.get(`/user/list/count`);
    return response.data;
  },

  tickets: async () => {
    const response = await api.get(`/ticket/list/data`);
    return response.data;
  },

  cities: async () => {
    const response = await api.get(`/city`);
    return response.data;
  },

  merchants: async () => {
    const response = await api.get(`/merchant`);
    return response.data;
  },

  healthCheck: async () => {
    const response = await api.get("/mon/health");
    return response.data;
  },
};

export default apiServices;
