import React, { useState } from "react";
import { TopNav } from "../components";
import handleFormAddProduct from "../helper/handleFormAddProduct";
import { useNavigate } from "react-router-dom";
import useStore from "../store";
import { formatRupiah } from "../helper";

const AddProduct = () => {
  const navigate = useNavigate();
  const [productName, setProductName] = useState("");
  const [productDesc, setProductDesc] = useState("");
  const [price, setPrice] = useState("");
  const [imgUrl, setImgUrl] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const setNotification = useStore((state) => state.setNotification);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImgUrl(file);
      setImagePreview(URL.createObjectURL(file));
    } else {
      setImgUrl(null);
      setImagePreview(null);
    }
  };

  const handlePriceChange = (e) => {
    const value = e.target.value;
    const numberValue = value.replace(/[^0-9]/g, ""); // Hanya angka yang diizinkan
    setPrice(numberValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("productName", productName);
    formData.append("productDesc", productDesc);
    formData.append("price", price);
    formData.append("image", imgUrl);
    const response = await handleFormAddProduct(formData, setLoading, setError);

    if (response) {
      setNotification("product created successfully!", "success");
      navigate("/product");
    } else {
      setNotification("failed to create product", "error");
    }
  };

  return (
    <>
      <TopNav pageName={"Tambah Product"} isRightCustom={true} />
      <div className="flex flex-col items-center justify-center px-[20px]">
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Nama Produk
            </label>
            <input
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm p-2"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Deskripsi Produk
            </label>
            <textarea
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm p-2"
              value={productDesc}
              onChange={(e) => setProductDesc(e.target.value)}
              required
            ></textarea>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Harga
            </label>
            <input
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm p-2"
              value={formatRupiah(price)}
              onChange={handlePriceChange}
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Upload Gambar
            </label>
            <input
              type="file"
              accept="image/*"
              className="mt-1 block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer focus:outline-none"
              onChange={handleImageChange}
              required
            />
            {imagePreview && (
              <div className="mt-4">
                <img
                  src={imagePreview}
                  alt="Preview"
                  className="w-full h-auto rounded-md"
                />
              </div>
            )}
          </div>
          {error && <div className="text-red-500">{error}</div>}
          <button
            type="submit"
            className={`py-3 w-full font-semibold capitalize rounded-lg ${
              !productName || !productDesc || !price || !imgUrl || loading
                ? "bg-gray-100 cursor-not-allowed text-gray-400"
                : "bg-blue-600 hover:bg-blue-700 text-white"
            }`}
            disabled={
              !productName || !productDesc || !price || !imgUrl || loading
            }
          >
            {loading ? "Loading..." : "Tambahkan Produk"}
          </button>
        </form>
      </div>
    </>
  );
};

export default AddProduct;
