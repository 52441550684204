import { useEffect, useState } from "react";
import useStore from "../store";

const useFetchUserCount = (userId) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const userCount = useStore((state) => state.userCount);
  const fetchUserCount = useStore((state) => state.fetchUserCount);

  useEffect(() => {
    const fetch = async () => {
      try {
        setIsLoading(true);
        setError(null);
        await fetchUserCount();
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetch();
  }, [fetchUserCount]);

  return { userCount, isLoading, error };
};

export default useFetchUserCount;
