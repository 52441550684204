import React, { useEffect } from "react";
import { TopNav } from "../components";
import useStore from "../store";
import { UserCircle } from "iconoir-react";

function Profile() {
  const logout = useStore((state) => state.logout);
  const profile = useStore((state) => state.profile);
  const user = useStore((state) => state.user);
  const isLoading = useStore((state) => state.isLoading);
  const error = useStore((state) => state.error);

  useEffect(() => {
    if (!user) {
      profile();
    }
  }, [user, profile]);

  const handleLogout = () => logout();

  return (
    <div>
      <TopNav pageName={"Profile"} rightIcon={false} isRightCustom={true} />
      <div className="px-4 space-y-[100px]">
        <div className="profile-content w-full">
          {isLoading ? (
            <p>Loading...</p>
          ) : error ? (
            <p>{error}</p>
          ) : (
            user && (
              <div className="flex flex-col w-full justify-center items-center space-y-8">
                <div className="flex flex-col items-center space-y-2">
                  <UserCircle width={100} height={100} color="#2bb3ff80" />
                  <div className="text-[24px] font-bold text-black">
                    {user.fullname}
                  </div>
                </div>
                <div className="w-full text-[14px] space-y-3">
                  <div className="flex flex-row justify-between w-full">
                    <div className="text-[#9c9c9c]">Email </div>
                    <div className="text-[#343434]">{user.email}</div>
                  </div>
                  <div className="flex flex-row justify-between w-full">
                    <div className="text-[#9c9c9c]">WhatsApp</div>
                    <div className="text-[#343434]">{user.whatsapp}</div>
                  </div>
                  <div className="flex flex-row justify-between w-full">
                    <div className="text-[#9c9c9c]">Username </div>
                    <div className="text-[#343434]">{user.username}</div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
        <div className="mx-auto w-1/2 my-2 bg-transparent rounded-lg items-center justify-center">
          <button
            onClick={handleLogout}
            className="py-[15px] w-[100%] font-semibold capitalize text-[#fa4e4e]"
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
}

export default Profile;
