import { HomeSimpleDoor, PageFlip, HandCash } from "iconoir-react";
import React from "react";
import { useLocation, useNavigate } from "react-router";
import { IconText } from "../components";

const BottomNav = () => {
  const navigate = useNavigate();
  const match = useLocation();

  const onPressHome = () => {
    navigate("..");
  };

  const onPressTicket = () => {
    navigate("../product");
  };

  const onPressTransaction = () => {
    navigate("../transaction");
  };

  const hiddenBar =
    match.pathname === "/" ||
    match.pathname === "/product" ||
    match.pathname === "/transaction";

  const Main = () => {
    return (
      <div className="fixed w-full z-99 bottom-0 flex justify-center shadow-lg border-t">
        <div className="w-full bg-white">
          <div className="h-[80px] flex items-center justify-around">
            <button
              onClick={onPressHome}
              className="w-[60px] h-12 flex justify-center"
            >
              <IconText.V
                label="Home"
                fontStyle={
                  match.pathname === "/"
                    ? "text-[10px] font-bold text-blue-500"
                    : "text-[10px] font-semibold text-gray-500"
                }
                icon={() =>
                  match.pathname === "/" ? (
                    <HomeSimpleDoor
                      className="text-blue-500"
                      width={28}
                      height={28}
                      strokeWidth={2.5}
                    />
                  ) : (
                    <HomeSimpleDoor
                      className="text-gray-500"
                      width={28}
                      height={28}
                      strokeWidth={2}
                    />
                  )
                }
              />
            </button>
            <button
              onClick={onPressTicket}
              className="w-[60px] h-12 flex justify-center"
            >
              <IconText.V
                label="Product"
                fontStyle={
                  match.pathname === "/product"
                    ? "text-[10px] font-bold text-blue-500"
                    : "text-[10px] font-semibold text-gray-500"
                }
                icon={() =>
                  match.pathname === "/product" ? (
                    <PageFlip
                      className="text-blue-500"
                      width={28}
                      height={28}
                      strokeWidth={2.5}
                    />
                  ) : (
                    <PageFlip
                      className="text-gray-500"
                      width={28}
                      height={28}
                      strokeWidth={2}
                    />
                  )
                }
              />
            </button>
            <button
              onClick={onPressTransaction}
              className="w-[60px] h-12 flex justify-center"
            >
              <IconText.V
                label="Trx"
                fontStyle={
                  match.pathname === "/transaction"
                    ? "text-[10px] font-bold text-blue-500"
                    : "text-[10px] font-semibold text-gray-500"
                }
                icon={() =>
                  match.pathname === "/transaction" ? (
                    <HandCash
                      className="text-blue-500"
                      width={28}
                      height={28}
                      strokeWidth={2.5}
                    />
                  ) : (
                    <HandCash
                      className="text-gray-500"
                      width={28}
                      height={28}
                      strokeWidth={2}
                    />
                  )
                }
              />
            </button>
          </div>
        </div>
      </div>
    );
  };

  return hiddenBar ? <Main /> : null;
};

export default BottomNav;
