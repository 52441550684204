import apiServices from "../../services/apiService";

const createPaymentSlice = (set, get) => ({
  payments: [],
  paymentCount: null,
  fetchPaymentCount: async (status) => {
    try {
      const response = await apiServices.paymentCount(status);
      set({ paymentCount: response });
      return response;
    } catch (error) {
      console.error("payments load failed:", error);
      return null;
    }
  },
});

export default createPaymentSlice;
